import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {API, graphqlOperation} from 'aws-amplify';
import {getArticle} from '../../graphql/queries';
import {createArticle, updateArticle} from '../../graphql/mutations';
import RichTextEditor from 'react-rte';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {withAuthenticator} from '@aws-amplify/ui-react';
import Header from '../sections/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  
  
  
}));

function ArticleCreateEdit() {
  const { id } = useParams();
  
  const [article, setArticle] = useState({ })

  useEffect(() => {
    console.log(id);
    if(id !== undefined){
      API.graphql( { query:getArticle,
        variables: {id: id},
        authMode: "AWS_IAM"})
      .then(article => {
          console.log(article.data.getArticle);
          let articleItem = article.data.getArticle;
          setArticle({...articleItem, 
            description:RichTextEditor.createValueFromString(articleItem.description, 'html') });
      })
    }
  }, [])


  function handleInput(changes){
    delete article.createdAt;
    delete article.updatedAt;
    setArticle({...article, ...changes});
  }

  function handleChange(value){
    delete article.createdAt;
    delete article.updatedAt;
    delete article.owner;
    console.log(value)
    setArticle({...article, description : value});
    
  }
  
  function handleOnSubmit(){
    delete article.createdAt;
    delete article.updatedAt;
    delete article.owner;
    console.log(article)
    if(id !== undefined){
      API.graphql(graphqlOperation(updateArticle,{input : {...article, 
        publishedDate: new Date(),
        description: article.description.toString('html'), type: 'AEM'}}));
    } else {
      API.graphql(graphqlOperation(createArticle,{input : {...article,
        publishedDate: new Date(),
        description: article.description.toString('html'), type: 'AEM'}}));
    
    }
  }

  const classes = useStyles();
    
    return (
        <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}><Header /></Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className={classes.paper}>TITLE</Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Paper className={classes.paper}>
                      <span><input type="text" name="title" id="title" defaultValue={article.title} 
                      style={{width: "100%"}}
                      onInput={e => handleInput({title: e.target.value})} /> </span>
                </Paper>
            </Grid>

                        
            <Grid item xs={12} sm={3}>
                <Paper className={classes.paper}>SUMMARY</Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Paper className={classes.paper}>
                  <span><textarea style={{width: "100%",height: "70px"}}
                        name="summary" id="summary" defaultValue={article.summary} onInput={e => handleInput({summary: e.target.value})}  /> </span>
                    
                </Paper>
            </Grid>

                        
            <Grid item xs={12} sm={3}>
                <Paper className={classes.paper}>DESCRIPTION</Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Paper className={classes.paper}>
                  <span>
                      <RichTextEditor
                          value={article.description !== undefined ? article.description : RichTextEditor.createEmptyValue()}
                          onChange={handleChange} 
                        />
                  </span>   
                </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
                <Paper className={classes.paper}>VIDEO ID</Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Paper className={classes.paper}>
                      <span><input type="text" name="videoId" id="videoId" defaultValue={article.videoId} 
                      style={{width: "100%"}}
                      onInput={e => handleInput({videoId: e.target.value})} /> </span>
                </Paper>
            </Grid>            

            <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                <div>
                    <input type="submit" onClick={e => handleOnSubmit()}/>
                </div>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>FOOTER</Paper>
            </Grid>
        </Grid>
        </div>
    );

   
}


export default withAuthenticator(ArticleCreateEdit);