import React, {useState} from 'react'
import {Auth} from "aws-amplify";


function RegistrationPage() {

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const [signUp, setSignUp] = useState(false);
    const [regSubmitClicked, setRegSubmitClicked] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    async function onRegistrationSubmit(e){
        setRegSubmitClicked(true);
        setErrMsg(null);
        try {
            const user = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    given_name: firstName,
                    family_name: lastName
                }
            });
            setSignUp(true);
        } catch (e) {
            console.log(e.message);
            switch (e.message){
                case "Username cannot be empty":
                    setErrMsg("Email address cannot be empty");
                    break;
                case "Attribute value for given_name must not be null":
                    setErrMsg("First name cannot be empty");
                    break;
                default:
                    setErrMsg(e.message);
            }
            setRegSubmitClicked(false);
        }
    }
    return (
        <>
        <section class="container mx-auto mt-5 mb-5" >
            {errMsg &&
            <div className="alert alert-danger" role="alert">
                {errMsg}
            </div>
            }

            {!signUp &&

                <div class="row align-items-center h-100">
                    <div class="col-6 mx-auto">

                    <div class="mb-3">
                        <label for="firstName" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="firstName" aria-describedby="emailHelp"
                            onChange={ e => setFirstName(e.target.value)}
                        />
                    </div>
                    <div class="mb-3">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="lastName" aria-describedby="emailHelp"
                                            onChange={ e => setLastName(e.target.value)}
                                            />
                    </div>

                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input type="email" class="form-control" id="email" aria-describedby="emailHelp"

                        onChange={ e => setEmail(e.target.value)}
                        />
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" class="form-control" id="password"
                                            onChange={ e => setPassword(e.target.value)}

                        />
                    </div>
                        {!regSubmitClicked &&
                            <button type="submit" class="btn btn-primary"
                                onClick={e => onRegistrationSubmit(e)}>Register</button>
                        }
                        {regSubmitClicked &&
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        }
                    </div>


                </div>
            }

            {signUp &&

                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">Congratulations!</h4>
                    <p>Thanks for signing up. Please check your inbox to get the account verification code. Please check your SPAM or JUNK folder.</p>

                </div>
            }

        </section>
    </>    
            
        )
}


export default RegistrationPage;