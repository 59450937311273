import React from 'react';

import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import {withAuthenticator} from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

function Quizzes() {
    return (
        <div className="Quizzes">
            This is the list page for Quizzes
        </div>
    );
}
  
export default withAuthenticator(Quizzes);