import React, {useEffect, useState} from 'react'
import {API, graphqlOperation} from "aws-amplify";
import {cartByUserId, getCourse} from "../../graphql/queries";
import {Col, Container, Row} from "react-bootstrap";
import {getAuthenticatedUserInfo} from "../../facade/AuthFacade";
import {deleteCart} from "../../graphql/mutations";
import CheckoutButton from "../bands/CheckoutButton";

export default function CheckoutPage() {

    const [cartItems, setCartItems] = useState([]);
    const [userId, setUserId] =  useState(null);


    useEffect(() => {
        async function getData() {
            const user = await getAuthenticatedUserInfo();
            setUserId(user.attributes.sub);
            const cart = await API.graphql(
                graphqlOperation(cartByUserId, {userId: user.attributes.sub})
            );
            const arr = [];

            for (const item of cart.data.cartByUserId.items) {
                const courseItem = await API.graphql(
                    graphqlOperation(getCourse, {id: item.courseId})
                )
                if (courseItem !== null) {
                    courseItem.data.getCourse.cartId = item.id;
                    arr.push(courseItem.data.getCourse);
                }
            }
            console.log(arr);
            setCartItems(arr);
            console.log(cartItems)
        }

        getData();
    }, [])

    async function removeItem(id){
        const courseItem = await API.graphql(
            graphqlOperation(deleteCart, {input: {id: id} })
        )
    }

    async function placeOrder(){
        const courseId = cartItems[0].id;
        console.log(courseId);
        console.log(userId);

        const apiName = "contentapi"
        const apiEndpoint = "/content/placeorder"
        const body = {
            userId: userId,
            courseId: courseId
        }
        const order = await API.post(apiName, apiEndpoint, { body })
        console.log(order);

    }

    return (


                <Container className={"pt-5 pb-5"}>
                    {cartItems.length > 0 && cartItems.map(item => {
                        return (
                            <Row key={item.id} >
                                <Col  xs={3}>
                                    <img src={item.image} width={120}/>
                                </Col>
                                <Col xs={6}>
                                    {item.title}
                                </Col>
                                <Col  xs={3}>
                                    $9.99
                                </Col>
                            </Row>

                        )

                    })
                    }

                    <Row>
                        <Col xs={3}>
                        </Col>
                        <Col xs={6}>
                            Total
                        </Col>
                        <Col xs={3}>
                            $9.99
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                        </Col>
                        <Col xs={6}>
                        </Col>
                        <Col xs={3}>
                            <CheckoutButton/>
                        </Col>
                    </Row>
                </Container>



    )
}
