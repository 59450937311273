import React, {useEffect, useState} from 'react'
import {getAuthenticatedUserInfo} from '../../facade/AuthFacade';
import {Col, Container, Row} from "react-bootstrap";
import {S3Image} from 'aws-amplify-react';

export default function ProfilePage() {

    const [firstName, setfirstName] = useState(null);
    const [lastName, setlastName] = useState(null);
    const [email, setemail] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        async function getData(){
            const user = await getAuthenticatedUserInfo();
            console.log(user);
            setfirstName(user.attributes.given_name);
            setlastName(user.attributes.family_name);
            setemail(user.attributes.email);
            setId(user.attributes.sub);
        }
        getData();
    }, [])


    return (
        <>
            <section className="container mx-auto mt-5 mb-5 p-5" >
                <div className="row justify-content-center mb-5">
                    <S3Image imgKey={id}  picker={true} theme={{ photoImg: { width: '200px', height: '200px' } }}/>
                </div>


                <Container>
                    <Row>
                        <Col sm={3}></Col>
                        <Col sm={3}><p className="font-weight-bold">FIRST NAME</p></Col>
                        <Col sm={3}>{firstName}</Col>
                        <Col sm={3}></Col>
                    </Row>
                    <Row>
                        <Col sm={3}></Col>
                        <Col sm={3}><p className="font-weight-bold">LAST NAME</p></Col>
                        <Col sm={3}>{lastName}</Col>
                        <Col sm={3}></Col>
                    </Row>
                    <Row>
                        <Col sm={3}></Col>
                        <Col sm={3}><p className="font-weight-bold">EMAIL</p></Col>
                        <Col sm={3}>{email}</Col>
                        <Col sm={3}></Col>
                    </Row>
                </Container>


            </section>
        </>
    )
}
