import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Auth } from 'aws-amplify';


function ForgotPassword() {

    const [email, setEmail] = useState(null);
    const [code, setCode] = useState(null);
    const [password, setPassword] = useState(null);

    const [showPasswordResetModal,setShowPasswordResetModal] = useState(false);
    const [showPasswordSuccessModal,setShowPasswordSuccessModal] = useState(false);

    const [resetSubmitClicked, setResetSubmitClicked] = useState(false);
    const [newPassSubmitClicked, setNewPassSubmitClicked] = useState(false);

    const [errMsg, setErrMsg] = useState(null);

    async function onResetSubmit(e) {
        e.preventDefault();
        setResetSubmitClicked(true);
        if(email == null){
            setResetSubmitClicked(false);
        } else {
            try {
                const user = await Auth.forgotPassword(email);
                console.log(user);
                setResetSubmitClicked(false);
                setShowPasswordResetModal(true);
            } catch (error) {
                if(error.code === "UserNotFoundException"){
                    setErrMsg("User Account Not Found!!!")
                    setResetSubmitClicked(false);
                }
                if(error.code === "LimitExceededException"){
                    setErrMsg(error.message);
                    setResetSubmitClicked(false);
                }
            }
        }

    }

    async function onNewPasswordSubmit(e) {
        e.preventDefault();
        setNewPassSubmitClicked(true);
        try{
            const user = await Auth.forgotPasswordSubmit(email, code, password);
            console.log(user);
            setShowPasswordSuccessModal(true);
            setShowPasswordResetModal(false);
        } catch (error) {
            if(error.code === "CodeMismatchException") {
                setNewPassSubmitClicked(false);
            }
            if(error.code === "InvalidPasswordException"){
                setErrMsg(error.message);
                setNewPassSubmitClicked(false);
            }
            if(error.code === "LimitExceededException"){
                setErrMsg(error.message);
                setNewPassSubmitClicked(false);
            }
        }

    }

    return (
        <>
            <section className="container mx-auto mt-5 mb-5" >

                <div className="row align-items-center h-100">
                    <div className="col-6 mx-auto">

                        <form>
                            {errMsg &&
                            <div className="alert alert-danger" role="alert">
                                {errMsg}
                            </div>
                            }
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                                    onChange={ e => setEmail(e.target.value)} />
                            </div>

                            {!resetSubmitClicked &&
                            <button type="submit" className="btn btn-primary"
                                    onClick={e => onResetSubmit(e)}>Reset Password</button>
                            }
                            {resetSubmitClicked &&
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                            }
                        </form>
                    </div>
                </div>
            </section>


            <Modal show={showPasswordResetModal} onHide={e => setShowPasswordResetModal(false)}>
                <Modal.Header>Please check your inbox</Modal.Header>
                <Modal.Body>

                    {errMsg &&
                    <div className="alert alert-danger" role="alert">
                        {errMsg}
                    </div>
                    }

                    <label htmlFor="code" className="form-label">Enter the code from your email</label>
                    <div className="mb-3">
                        <input type="text" className="form-control" id="code" aria-describedby="emailHelp"
                               onChange={ e => setCode(e.target.value)} />
                    </div>

                    <label htmlFor="password" className="form-label">Enter the new password</label>
                    <div className="mb-3">
                        <input type="password" className="form-control" id="password" aria-describedby="emailHelp"
                               onChange={ e => setPassword(e.target.value)} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {!newPassSubmitClicked &&
                        <button type="submit" className="btn btn-primary" onClick={e => onNewPasswordSubmit(e)}>Submit</button>
                    }
                    {newPassSubmitClicked &&
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                    }

                </Modal.Footer>
            </Modal>


            <Modal show={showPasswordSuccessModal} onHide={e => setShowPasswordSuccessModal(false)}>
                <Modal.Header>Password Reset Success!!!</Modal.Header>
                <Modal.Body>
                    Your password has been changed successfully.
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary" onClick={e => window.location.href="/login" }>Sign In</button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default ForgotPassword;