import React, {useState} from 'react'
import {Auth} from "aws-amplify";
import Modal from "react-bootstrap/Modal";

function LoginPage() {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [code, setCode] = useState(null);

    const [showConfCodeModal, setShowConfCodeModal] = useState(false);
    const [showLoginErrorModal, setShowLoginErrorModal] = useState(false)
    const [showUserNotFoundModal, setShowUserNotFoundModal] = useState(false);
    const [confCodeModalErr, setConfCodeModalErr] = useState(null);

    const [loginSubmitClicked, setLoginSubmitClicked] = useState(false);
    const [verifySubmitClicked, setVerifySubmitClicked] = useState(false);

    async function onLoginSubmit(e){
        e.preventDefault();
        setLoginSubmitClicked(true);
        try{
            const user = await Auth.signIn(email, password);
            if(user.code === undefined){
                window.location.href = "/";
            }
        } catch ( error ) {
            setLoginSubmitClicked(false);

            if(error.code === "UserNotConfirmedException"){
                setShowConfCodeModal(true);
            }
            if(error.code === "NotAuthorizedException"){
                setShowLoginErrorModal(true);
            }
            if(error.code === "UserNotFoundException"){
                setShowUserNotFoundModal(true);
            }

        }
         
    }

    async function handleConfirmationCode(e){
        try {
            setVerifySubmitClicked(true);
            await Auth.confirmSignUp(email, code);
            setShowConfCodeModal(false);
            await onLoginSubmit(e);
        } catch (error) {
            if(error.code === "CodeMismatchException"){
                setConfCodeModalErr(error.message);
            }
        }
    }  

    return (
            <>
                <section className="container mx-auto mt-5 mb-5" >

                    <div className="row align-items-center h-100">
                        <div className="col-6 mx-auto">

                        <form>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                            onChange={ e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" className="form-control" id="password" 
                            onChange={ e => setPassword(e.target.value)}
                            />
                            <a href="/forgot-password">Forgot Password</a>
                        </div>

                            {!loginSubmitClicked &&
                                <button type="submit" className="btn btn-primary"
                                    onClick={e => onLoginSubmit(e)}>Login</button>
                            }
                            {loginSubmitClicked &&
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            }

                        </form>


                        </div>
                    </div>
                </section> 

                <Modal show={showConfCodeModal} onHide={e => setShowConfCodeModal(false)}>
                    <Modal.Header>VERIFY ACCOUNT</Modal.Header>
                    <Modal.Body>
                        It looks like your email has not been verified. 
                        Please enter the code from your email
                        <div className="mb-3">
                            <input type="text" className="form-control" id="email" aria-describedby="emailHelp"
                            onChange={ e => setCode(e.target.value)}
                            />
                            {confCodeModalErr &&
                            <div className="alert alert-danger" role="alert">
                                {confCodeModalErr}
                            </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!verifySubmitClicked &&
                            <button type="submit" className="btn btn-primary"
                                onClick={e => handleConfirmationCode(e)}>Verify</button>
                        }
                        {verifySubmitClicked &&
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        }


                    </Modal.Footer>
                </Modal>

                <Modal show={showLoginErrorModal} onHide={e => setShowLoginErrorModal(false)}>
                    <Modal.Header>LOGIN ERROR</Modal.Header>
                    <Modal.Body>
                        Incorrect username or password
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" onClick={e => setShowLoginErrorModal(false)}>Try Again</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showUserNotFoundModal} onHide={e => setShowUserNotFoundModal(false)}>
                    <Modal.Header>USER NOT FOUND</Modal.Header>
                    <Modal.Body>
                        The email address you are trying is not found in our system. Please register on the site to create a new account
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-secondary" onClick={e => setShowUserNotFoundModal(false)}>Try Again</button>
                        <button type="submit" className="btn btn-primary" onClick={e => window.location.href="/register" }>Register</button>
                    </Modal.Footer>
                </Modal>
            </>    
        )
}


export default LoginPage;