/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      title
      summary
      description
      videoId
      type
      publishedDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      title
      summary
      description
      videoId
      type
      publishedDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      title
      summary
      description
      videoId
      type
      publishedDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDictionary = /* GraphQL */ `
  mutation CreateDictionary(
    $input: CreateDictionaryInput!
    $condition: ModelDictionaryConditionInput
  ) {
    createDictionary(input: $input, condition: $condition) {
      id
      word
      meaning
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDictionary = /* GraphQL */ `
  mutation UpdateDictionary(
    $input: UpdateDictionaryInput!
    $condition: ModelDictionaryConditionInput
  ) {
    updateDictionary(input: $input, condition: $condition) {
      id
      word
      meaning
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDictionary = /* GraphQL */ `
  mutation DeleteDictionary(
    $input: DeleteDictionaryInput!
    $condition: ModelDictionaryConditionInput
  ) {
    deleteDictionary(input: $input, condition: $condition) {
      id
      word
      meaning
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createVodAsset = /* GraphQL */ `
  mutation CreateVodAsset(
    $input: CreateVodAssetInput!
    $condition: ModelvodAssetConditionInput
  ) {
    createVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      video {
        id
        token
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVodAsset = /* GraphQL */ `
  mutation UpdateVodAsset(
    $input: UpdateVodAssetInput!
    $condition: ModelvodAssetConditionInput
  ) {
    updateVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      video {
        id
        token
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVodAsset = /* GraphQL */ `
  mutation DeleteVodAsset(
    $input: DeleteVodAssetInput!
    $condition: ModelvodAssetConditionInput
  ) {
    deleteVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      video {
        id
        token
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVideoObject = /* GraphQL */ `
  mutation CreateVideoObject(
    $input: CreateVideoObjectInput!
    $condition: ModelvideoObjectConditionInput
  ) {
    createVideoObject(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const updateVideoObject = /* GraphQL */ `
  mutation UpdateVideoObject(
    $input: UpdateVideoObjectInput!
    $condition: ModelvideoObjectConditionInput
  ) {
    updateVideoObject(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideoObject = /* GraphQL */ `
  mutation DeleteVideoObject(
    $input: DeleteVideoObjectInput!
    $condition: ModelvideoObjectConditionInput
  ) {
    deleteVideoObject(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      title
      summary
      description
      image
      originalPrice
      discountedPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      title
      summary
      description
      image
      originalPrice
      discountedPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      title
      summary
      description
      image
      originalPrice
      discountedPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      title
      courseId
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      title
      courseId
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      title
      courseId
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCourseReview = /* GraphQL */ `
  mutation CreateCourseReview(
    $input: CreateCourseReviewInput!
    $condition: ModelCourseReviewConditionInput
  ) {
    createCourseReview(input: $input, condition: $condition) {
      id
      comment
      userId
      firstName
      lastName
      rating
      courseId
      approved
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCourseReview = /* GraphQL */ `
  mutation UpdateCourseReview(
    $input: UpdateCourseReviewInput!
    $condition: ModelCourseReviewConditionInput
  ) {
    updateCourseReview(input: $input, condition: $condition) {
      id
      comment
      userId
      firstName
      lastName
      rating
      courseId
      approved
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCourseReview = /* GraphQL */ `
  mutation DeleteCourseReview(
    $input: DeleteCourseReviewInput!
    $condition: ModelCourseReviewConditionInput
  ) {
    deleteCourseReview(input: $input, condition: $condition) {
      id
      comment
      userId
      firstName
      lastName
      rating
      courseId
      approved
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLecture = /* GraphQL */ `
  mutation CreateLecture(
    $input: CreateLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    createLecture(input: $input, condition: $condition) {
      id
      title
      description
      order
      sectionId
      videoPath
      courseId
      duration
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLecture = /* GraphQL */ `
  mutation UpdateLecture(
    $input: UpdateLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    updateLecture(input: $input, condition: $condition) {
      id
      title
      description
      order
      sectionId
      videoPath
      courseId
      duration
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLecture = /* GraphQL */ `
  mutation DeleteLecture(
    $input: DeleteLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    deleteLecture(input: $input, condition: $condition) {
      id
      title
      description
      order
      sectionId
      videoPath
      courseId
      duration
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelcartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      courseId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelcartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      courseId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelcartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      courseId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelorderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      courseId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelorderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      courseId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelorderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      courseId
      userId
      createdAt
      updatedAt
    }
  }
`;
