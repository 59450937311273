import React from "react"
import {API} from "aws-amplify"
import {loadStripe} from "@stripe/stripe-js"
import {getAuthenticatedUserInfo} from "../../facade/AuthFacade";


const stripePromise = loadStripe("pk_live_51JB0x0D8IbHxkl4YFKCraFEQeOR21ZQgp6Za6w60GacXA2iE6P0SYvfWVjVC6Qcp7GGweOiWbBAEgTRIvVy7yG1h00xbeYTSxk")

const CheckoutButton = ({ courseId }) => {
    const redirectToCheckout = async () => {
        const fetchSession = async () => {
            const user = await getAuthenticatedUserInfo();
            console.log('course id: '+ courseId);
            const clientId = user.attributes.sub;
            const apiName = "contentapi"
            const apiEndpoint = "/content/checkout"
            const body = {
                quantity: 1,
                client_reference_id: clientId,
                priceId: "price_1KGgeqD8IbHxkl4YByMudagz",
                courseId: courseId
            }
            const session = await API.post(apiName, apiEndpoint, { body })
            return session
        }

        const session = await fetchSession()
        const sessionId = session.id
        const stripe = await stripePromise
        stripe.redirectToCheckout({ sessionId })
    }

    return <button className="btn btn-primary" onClick={redirectToCheckout}>Buy the course</button>
}

export default CheckoutButton