import React, {useEffect, useState} from 'react'
import {API} from 'aws-amplify';
import {articlesByPublishedDate} from '../../graphql/queries';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '180ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));


export default function ArticleItemList() {
    
    const [articles, setArticles] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        API.graphql({
            query: articlesByPublishedDate,
            variables: { sortDirection: 'DESC', type: 'AEM'},
            authMode: "AWS_IAM"
          })
        .then(articles => {
            setArticles(articles.data.articlesByPublishedDate.items)
        })
    }, [])

    function handleClick(value){
        console.log('handleClick');
        console.log(value);
        window.location.pathname='/aem/detail/'+value;
    }
    
    console.log('articles');
    return (

        <>
            <List className={classes.root}>

                {articles.map((article,idx) => {
                return (
                    <div key={idx}>
                        <ListItem  button alignItems="flex-start" onClick={e => handleClick(article.id)}>
                            <ListItemText
                                primary={article.title}
                                secondary={
                                    <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        
                                    </Typography>
                                    {article.summary}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider />
                        
                    </div>
                )})}
            </List>
        
        </>
    )
}
