import React, {useEffect, useState} from 'react'
import {API, graphqlOperation} from "aws-amplify";
import {getCourse, orderByUserId} from "../../graphql/queries";
import {Button, Container} from "react-bootstrap";
import {getAuthenticatedUserInfo} from "../../facade/AuthFacade";

export default function LearningPage() {

    const [userId, setUserId] =  useState(null);
    const [orderItems, setOrderItems] = useState(null);

    useEffect(() => {
        async function getData() {
            const user = await getAuthenticatedUserInfo();
            setUserId(user.attributes.sub);
            console.log(user);
            const orders = await API.graphql(
                graphqlOperation(orderByUserId, {userId: user.attributes.sub})
            );
            console.log(orders);
            const arr = [];

            for (const item of orders.data.orderByUserId.items) {
                const courseItem = await API.graphql(
                    graphqlOperation(getCourse, {id: item.courseId})
                )
                if (courseItem !== null) {
                    arr.push(courseItem.data.getCourse);
                }
            }

            setOrderItems(arr);
        }
        getData();
    }, [])

    return (


        <Container className={"pt-5 pb-5"}>

            { orderItems == null &&
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            }

            {orderItems != null && orderItems.length == 0 &&
                <div className="row">
                    <div className="col-md-auto">
                        No courses.
                    </div>
                </div>
            }

            {orderItems != null && orderItems.length > 0 && orderItems.map(item => {

                const link = "/course/video/"+item.id;

                return (


                <div key={item.id} className="row">
                    <div className="col-md-auto">
                        <img alt={item.title} src={item.image} width={120}/>
                    </div>

                    <div className="col col-lg-8">
                        {item.title}
                    </div>
                    <div className="col-md-auto">
                        <Button variant="primary" href={link}>Resume Watching</Button>
                    </div>
                </div>
                )

            })
            }


        </Container>



    )
}
