import React, {useEffect, useState} from 'react'

import {Col, ListGroup, Row} from "react-bootstrap";
import {Button, Jumbotron, Tab, Tabs} from "react-bootstrap";

import 'react-accessible-accordion/dist/fancy-example.css';



import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import {useParams} from "react-router-dom";
import {API, graphqlOperation} from "@aws-amplify/api";
import {lecturesBySectionId, lecturesByCourseId, sectionsByCourseId, listCourseReviews} from "../../graphql/queries";
import ReactPlayer from "react-player";
import axios from "axios";
import {Cache} from "aws-amplify";
import ReviewComponent from "../bands/ReviewComponent";
import ReactStars from "react-rating-stars-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



function VideoPlayerPage() {


  const classes = useStyles();

  const [sections, setSections] = useState([]);
  const [sectionsToLectures, setSectionsToLecture] = useState({});

  const [path, setPath] = useState(null);

  const { id } = useParams();



  useEffect( () => {
    async function getData(){

      let sectionItems = Cache.getItem("sectionsByCourseId"+id);
      if(sectionItems == null) {
        sectionItems = await API.graphql(
            graphqlOperation(sectionsByCourseId, {courseId: id})
        );
        Cache.setItem("sectionsByCourseId"+id, sectionItems);
      }

      let lectureItemsByCourseId = Cache.getItem("lecturesByCourseId"+id);
      if(lectureItemsByCourseId == null) {
        lectureItemsByCourseId = await API.graphql(
            graphqlOperation(lecturesByCourseId, {courseId: id})
        );
        Cache.setItem("lecturesByCourseId"+id, lectureItemsByCourseId);
      }

      for(const section of sectionItems.data.sectionsByCourseId.items){
        sectionsToLectures[section.id] = lectureItemsByCourseId.data.lecturesByCourseId.items.filter(l => l.sectionId === section.id).sort(function(a, b) {
          return a.order - b.order;
        });
      }




      setSections(sectionItems.data.sectionsByCourseId.items.sort(function(a, b) {
        return a.order - b.order;
      }));

    }

    getData();

  }, [])


  useEffect(() => {
    async function getData(){
      const data = await axios.get(`https://contentapi.donotstoplearning.com/content/checkauth?`, { withCredentials: true});
      setPath('https://video.donotstoplearning.com'+'/lecture-1/lecture-1.m3u8');
      return data;
    }
    
    getData();
  },[]);


  async function onLectureClick(videoPath){
    console.log('on lecture client');
    const url = "https://video.donotstoplearning.com"+ videoPath;
    setPath(url);
  }



  return (
    <div className={classes.root}>



      <section className="container mx-auto mt-5 mb-5 p-5" >

        {path &&
        <ReactPlayer className='react-player'
            // This is the video address passed from the superior page
                     url={path}
                     playing
                     width='100%'
                     controls height={500}
                     config={{
                       file: {
                         hlsOptions: {
                           xhrSetup: function(xhr, url) {
                             xhr.withCredentials = true;
                           }
                         }
                       }
                     }}
        />
        }

          <Row>
            <Col>
              { sections.length == 0 && path == null &&
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              }

              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Course Contents">

                { sections.map( section => {
                return (
                    <Accordion key={section.id} >
                      <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{section.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ListGroup className="w-100">
                          {sectionsToLectures[section.id] && sectionsToLectures[section.id].map(lecture => {
                            let min = Math.floor(lecture.duration/60);
                            let sec = lecture.duration - min * 60;
                            return (
                                <ListGroup.Item key={lecture.id}>
                                  <a href="#"  onClick={e => onLectureClick(lecture.videoPath)}>{lecture.title} - {min}m{sec}s</a>
                                </ListGroup.Item>
                            )
                          })
                          }

                        </ListGroup>

                      </AccordionDetails>
                    </Accordion>

                )
              })}

              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    disabled
                >
                  <Typography className={classes.heading}>MORE TO COME...</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ListGroup className="w-100">
                  </ListGroup>
                </AccordionDetails>
              </Accordion>

                </Tab>

                <Tab eventKey="reviews" title="Reviews">
                  <ReviewComponent />
                </Tab>

              </Tabs>


            </Col>
          </Row>


      </section>
    </div>
  );

}


export default VideoPlayerPage;