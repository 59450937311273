import React, {useEffect, useState} from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {getAuthenticatedUserInfo, logout} from '../../facade/AuthFacade';
import {Image} from "react-bootstrap";
import {Storage} from "@aws-amplify/storage";

export default function NavbarBand() {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [avatarPath, setAvatarPath] = useState(null);

    useEffect(() => {
        async function getData(){
            const user = await getAuthenticatedUserInfo();
            if(user !== null){
                setIsLoggedIn(true);
                Storage.get(user.attributes.sub).then((res) => {
                    console.log(res);
                    setAvatarPath(res)
                });
            }
        }
        getData();
    }, [])

    async function handleSignOut(e){
        await logout();
        console.log('handle signout');
        window.location.href = "/";
    }
    return (
        <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                <div className="container">
                    <Navbar.Brand href="/">DoNotStopLearning</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" className="justify-content-end" >
            
                        <Nav >

                            {isLoggedIn 
                            ?  
                                <>
                                    <Nav.Link href="/learning" className="text-white">My Learning</Nav.Link>
                                    <Nav.Link href="/profile" className="text-white">
                                        {avatarPath &&
                                            <Image src={avatarPath} onError={(event) => event.target.style.display = 'none'} roundedCircle height={20}/>
                                        }
                                        My Profile
                                    </Nav.Link>
                                    <Nav.Link href="#" onClick={e => handleSignOut(e)} className="text-white">Sign Out</Nav.Link>
                                </>
                            : 
                                <>
                                    <Nav.Link href="/register" className="text-white">Register</Nav.Link>
                                    <Nav.Link href="/login" className="text-white">Sign In</Nav.Link>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </div>        
            </Navbar>
    )
}
