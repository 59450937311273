/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVodAsset = /* GraphQL */ `
  query GetVodAsset($id: ID!) {
    getVodAsset(id: $id) {
      id
      title
      description
      video {
        id
        token
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVodAssets = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelvodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        video {
          id
          token
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideoObject = /* GraphQL */ `
  query GetVideoObject($id: ID!) {
    getVideoObject(id: $id) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const listVideoObjects = /* GraphQL */ `
  query ListVideoObjects(
    $filter: ModelvideoObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      summary
      description
      videoId
      type
      publishedDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        summary
        description
        videoId
        type
        publishedDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const articlesByPublishedDate = /* GraphQL */ `
  query ArticlesByPublishedDate(
    $type: String
    $publishedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByPublishedDate(
      type: $type
      publishedDate: $publishedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        description
        videoId
        type
        publishedDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDictionary = /* GraphQL */ `
  query GetDictionary($id: ID!) {
    getDictionary(id: $id) {
      id
      word
      meaning
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDictionarys = /* GraphQL */ `
  query ListDictionarys(
    $filter: ModelDictionaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDictionarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        word
        meaning
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      title
      summary
      description
      image
      originalPrice
      discountedPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        summary
        description
        image
        originalPrice
        discountedPrice
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      title
      courseId
      order
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        courseId
        order
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const sectionsByCourseId = /* GraphQL */ `
  query SectionsByCourseId(
    $courseId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionsByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        courseId
        order
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCourseReview = /* GraphQL */ `
  query GetCourseReview($id: ID!) {
    getCourseReview(id: $id) {
      id
      comment
      userId
      firstName
      lastName
      rating
      courseId
      approved
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCourseReviews = /* GraphQL */ `
  query ListCourseReviews(
    $filter: ModelCourseReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comment
        userId
        firstName
        lastName
        rating
        courseId
        approved
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLecture = /* GraphQL */ `
  query GetLecture($id: ID!) {
    getLecture(id: $id) {
      id
      title
      description
      order
      sectionId
      videoPath
      courseId
      duration
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLectures = /* GraphQL */ `
  query ListLectures(
    $filter: ModelLectureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLectures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        order
        sectionId
        videoPath
        courseId
        duration
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const lecturesBySectionId = /* GraphQL */ `
  query LecturesBySectionId(
    $sectionId: String
    $sortDirection: ModelSortDirection
    $filter: ModelLectureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesBySectionId(
      sectionId: $sectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        order
        sectionId
        videoPath
        courseId
        duration
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const lecturesByCourseId = /* GraphQL */ `
  query LecturesByCourseId(
    $courseId: String
    $sortDirection: ModelSortDirection
    $filter: ModelLectureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        order
        sectionId
        videoPath
        courseId
        duration
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      courseId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelcartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const cartByUserId = /* GraphQL */ `
  query CartByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelcartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      courseId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByUserId = /* GraphQL */ `
  query OrderByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
