import {Auth, Cache} from 'aws-amplify';


async function getAuthenticatedUserInfo(){
    console.log('auth facade')
    let user = Cache.getItem('user');
    if(user === null){
        user = await Auth.currentUserInfo();
        Cache.setItem('user', user);
        console.log('adding to cache')
    } else {
        console.log('retrieved from cache');
    }
    return user;
    
}

async function logout(){
    await Auth.signOut();
    Cache.removeItem('user');
}


export { getAuthenticatedUserInfo , logout}