import React, {useEffect, useState} from 'react'
import {ListGroup} from "react-bootstrap";

export default function SubscriptionPage() {


    const [tier, setTier] = useState("#free");

    useEffect(() => {
        
    }, [])

    return (
        <>
        <section className="container mx-auto mt-5 mb-5 p-5" >

            <ListGroup defaultActiveKey={tier}>
                <ListGroup.Item  action href="#free" onClick={e => setTier("free")}>
                    <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">FREE TIER</h5>
                    <small></small>
                    </div>
                    <p className="mb-1">Free membership for a limited time</p>
                    <small>No credit card required</small>
                </ListGroup.Item>
                <ListGroup.Item  action href="#monthly" onClick={e => setTier("monthly")}>
                    <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">MONTHLY PLAN</h5>
                    <small className="text-muted"></small>
                    </div>
                    <p className="mb-1">$9.99 per month</p>
                    <small className="text-muted">Credit card required</small>
                </ListGroup.Item>
                <ListGroup.Item  action href="#annually" onClick={e => setTier("annually")}>
                    <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">ANNUAL PLAN</h5>
                    <small classNameName="text-muted"></small>
                    </div>
                    <p className="mb-1">$69.99 per year</p>
                    <small className="text-muted">Credit card required</small>
                </ListGroup.Item>
            </ListGroup>
            
        </section>
        </>
    )
}
