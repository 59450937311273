import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-71559407-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();

  function handleClick(value){
      console.log('handleClick');
      console.log(value);
      window.location.pathname=value;
  }

  return (
    <Typography className={classes.root}>
      {
      <Link href="#" onClick={(event) => handleClick("/")} color="inherit">
        HOME
      </Link>
      }
      {/*
      <Link href="#" onClick={(event) => handleClick("/aem/list")} color="inherit">
        AEM
      </Link>
      */}
      {/* 
      <Link href="#" onClick={preventDefault} color="inherit">
        ABOUT
      </Link>
       */}
      </Typography>
  );
}
