import ReactStars from "react-rating-stars-component";
import React, {useEffect, useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import {createArticle, createCourseReview} from "../../graphql/mutations";
import {getAuthenticatedUserInfo} from "../../facade/AuthFacade";
import {useParams} from "react-router-dom";
import {ListGroup, Badge} from "react-bootstrap";
import {listCourseReviews, sectionsByCourseId} from "../../graphql/queries";




function ReviewComponent(){

    const [comment,setComment] = useState(null);
    const [rating,setRating] = useState(null);

    const [submitClicked, setSubmitClicked] = useState(false);
    const { id } = useParams();
    const [comments,setComments] = useState([]);

    async function onSubmit(e) {
        e.preventDefault();
        setSubmitClicked(true);
        console.log(rating);
        console.log(comment);
        const user = await getAuthenticatedUserInfo();
        console.log(user);

        API.graphql(graphqlOperation(createCourseReview, {
                input: {
                    comment: comment,
                    rating: rating,
                    firstName: user.attributes.given_name,
                    lastName: user.attributes.family_name,
                    userId: user.username,
                    courseId: id
                }
            }));

        setComment("");
        setSubmitClicked(false);

    }

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRating(newRating);
    };

    useEffect( () => {
        async function getData(){
            let reviews = await API.graphql(
                graphqlOperation(listCourseReviews)
            );
            console.log(reviews);
            if(reviews != null && reviews.data.listCourseReviews.items.length > 0) {
                setComments(reviews.data.listCourseReviews.items.filter(l => l.approved === true));
            }
        }
        getData();
    },[])

    return (
        <>

            <div className="row align-items-center h-100 mt-3 mb-3">
                <div className="col-6 mx-auto">

                    <form>
                        <div className="mb-3">
                            <label htmlFor="comment" className="form-label">Enter your comments</label>
                            <textarea id="comment"  rows="6" cols="50" onChange={e=> setComment(e.target.value)} value={comment}/>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="ratings" className="form-label">Select your ratings</label>
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={24}
                                activeColor="#ffd700"
                                value={5}
                            />
                        </div>

                        {!submitClicked &&
                        <button type="submit" className="btn btn-primary"
                                onClick={e => onSubmit(e)}>Submit</button>
                        }
                        {submitClicked &&
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        }

                    </form>


                </div>




            </div>


            <ListGroup as="ol">
                    {comments.length > 0 &&
                    comments.map(commentItem => {

                        return (
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                                key={commentItem.id}
                            >
                                <div className="ms-2 me-auto">
                                    <div className="font-weight-bold">{commentItem.firstName} {commentItem.lastName}</div>
                                    {commentItem.comment}
                                </div>
                                <ReactStars
                                    count={5}
                                    edit={false}
                                    size={24}
                                    activeColor="#ffd700"
                                    value={commentItem.rating}
                                />
                            </ListGroup.Item>
                        );
                    })

                }
            </ListGroup>
        </>
    )
}

export default ReviewComponent;