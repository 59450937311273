import React, {useEffect, useState} from 'react';
import './App.css';
import Quizzes from './components/Quizzes';
import {BrowserRouter, Route} from 'react-router-dom';
import ArticleListPage from './components/pages/ArticleListPage';
import ArticleCreateEdit from './components/edit/ArticleCreateEdit';
import ArticleDetailPage from './components/pages/ArticleDetailPage';
import RegistrationPage from './components/pages/RegistrationPage';
import LoginPage from './components/pages/LoginPage';
import VideoPlayerPage from './components/pages/VideoPlayerPage';
import Navbar from './components/bands/Navbar';
import Footer from './components/bands/Footer';
import {getAuthenticatedUserInfo} from './facade/AuthFacade';
import ProfilePage from './components/pages/ProfilePage';
import SubscriptionPage from './components/pages/SubscriptionPage';
import CoursePage from './components/pages/CoursePage';
import HomePage from './components/pages/HomePage';
import CheckoutPage from "./components/pages/CheckoutPage";
import LearningPage from "./components/pages/LearningPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";

function App() {

  const [userInfo, setUserInfo] = useState(null);
  const [unauthenticated, setUnauthenticated] = useState(false);

  useEffect(() => {
    getAuthenticatedUserInfo().then(user => { 
        if(user === null) setUnauthenticated(true);
        else setUserInfo(user);
      });
  }, [])


  return (
    <>
    <Navbar />
    <React.Fragment>
    <BrowserRouter>
      <div>
        
        {userInfo && 
          <>
            <Route path="/" exact={true} component={HomePage}/>
            <Route path="/video" exact={true} component={VideoPlayerPage}/>
            <Route path="/profile" exact={true} component={ProfilePage}/>
            <Route path="/subscription" exact={true} component={SubscriptionPage}/>
            <Route path="/course/:id" exact={true} component={CoursePage}/>
            <Route path="/course/video/:id" exact={true} component={VideoPlayerPage}/>
            <Route path="/checkout" exact={true} component={CheckoutPage}/>
            <Route path="/learning" exact={true} component={LearningPage}/>

            <Route path="/quizzes" component={Quizzes}/>
            <Route path="/app" component={App}/>
            <Route path="/aem/edit/:id" component={ArticleCreateEdit}/>
            <Route path="/aem/list" component={ArticleListPage}/>
            <Route path="/aem/create" component={ArticleCreateEdit}/>
            <Route path="/aem/detail/:id" component={ArticleDetailPage}/>
          </>   
         }

        {unauthenticated && 
          <switch>
           <Route path="/register" exact={true} component={RegistrationPage} />
           <Route path="/" exact={true} component={HomePage}/>
           <Route path="/login" exact={true} component={LoginPage}/>
           <Route path="/video" exact={true} component={LoginPage}/>
           <Route path="/profile" exact={true} component={LoginPage}/>
           <Route path="/subscription" exact={true} component={LoginPage}/>
           <Route path="/course/:id" exact={true} component={CoursePage}/>
           <Route path="/course/video/:id" exact={true} component={LoginPage}/>
           <Route path="/checkout" exact={true} component={LoginPage}/>
           <Route path="/learning" exact={true} component={LoginPage}/>
           <Route path="/forgot-password" exact={true} component={ForgotPasswordPage}/>


          </switch>
        }

      </div>
    </BrowserRouter>
    </React.Fragment>
    <Footer />
  </>
  );
}

export default App;
