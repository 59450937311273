import React, {useState} from 'react'
import Modal from "react-bootstrap/Modal";

export default function FooterBand() {


    const [showTCModal, setShowTCModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    return (
        <div className="footer">
            <div className="container">
                <div className="row align-items-center g-0 border-top py-2">
                    <div className="col-md-6 col-12 text-center text-md-start">
                        <span>© 2022 https://www.DoNotStopLearning.com. All Rights Reserved.</span>
                    </div>
                    <div className="col-12 col-md-6">
                        <nav className="nav nav-footer justify-content-center justify-content-md-end">
                            <a className="nav-link active ps-0" href="#" onClick={e => setShowPrivacyModal(true)}>Privacy</a>
                            <a className="nav-link" href="#" onClick={e => setShowTCModal(true)}>Terms & Conditions</a>
                            <a className="nav-link" href="mailto:njariwala@uberdigitech.com">Feedback</a>
                            <a className="nav-link" href="mailto:njariwala@uberdigitech.com">Support</a>
                        </nav>
                    </div>
                </div>
            </div>

            <Modal show={showTCModal} onHide={e => setShowTCModal(false)} size="xl">
                <Modal.Header>Terms & Conditions</Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <iframe src="https://app.termly.io/document/terms-of-use-for-saas/e7f17d02-815b-4789-8b9c-cdad83acc40f" width="100%" height="600px"/>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showPrivacyModal} onHide={e => setShowPrivacyModal(false)} size="xl">
                <Modal.Header>Privacy Policy</Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <iframe src="https://app.termly.io/document/privacy-policy/07c40d2f-4027-448c-b4d2-45010a37a21f" width="100%" height="600px"/>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
