import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {API} from 'aws-amplify';
import {getArticle} from '../../graphql/queries';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from '../sections/Header';
import Footer from '../sections/Footer';

import {Helmet} from "react-helmet";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function ArticleDetail() {

    const { id } = useParams();
    const [article, setArticle] = useState({ })

    useEffect(() => {
        console.log(id.toString())
        if(id !== ''){
          API.graphql({query: getArticle,
            variables: {id: id},
            authMode: "AWS_IAM" })
          .then(article => {
              let articleItem = article.data.getArticle;
              setArticle(articleItem);
          })
        }
      }, [])

    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <Helmet>
                <title>{article.title}</title>
                <meta name="description" content={article.summary} />
            </Helmet>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Header />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} style={{  overflow: 'auto' }}>
                <React.Fragment>
                    <CssBaseline />
                    <Container maxWidth="lg">
                        <Typography component="div" style={{  height: '100vh' }} >
                        <div>
                            <h2>{article.title}</h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: article.description
                                }}>

                            </div>
                            <div>
                                {
                                    article.videoId && <iframe width="900" height="600" src={"https://www.youtube.com/embed/"+article.videoId} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                                }
                            </div>
                                
                        </div>
                        </Typography>
                    </Container>
                </React.Fragment>
                
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}><Footer /></Paper>
            </Grid>
        </Grid>
        </div>
    );


}
